<template>
  <a-form-model
      ref="form"
      :model="form"
      v-bind="formItemLayoutWithOutLabel"
      class="form-model"
  >
    <a-form-model-item
        v-for="(domain, index) in userForms"
        :key="domain.id"
        v-bind="formItemLayout"
        :prop="domain.label"
        :colon="false"
        :label="domain.label"
    >
      <a-input
          v-if="domain.formType === 'input'"
          v-model="domain.value"
          :placeholder="domain.placeholder"
          size="small"
      />

      <a-textarea
          v-if="domain.formType === 'textarea'"
          v-model="domain.value"
          :placeholder="domain.placeholder"
      />

      <a-select
          v-if="domain.formType === 'select'"
          v-model="domain.value"
          size="small"
          :placeholder="domain.placeholder || '请选择'">
        <a-select-option v-for="item in domain.optionsList" :value="item">
          {{ item }}
        </a-select-option>
      </a-select>

      <a-select
          mode="multiple"
          v-if="domain.formType === 'multiSelect'"
          v-model="domain.value"
          :placeholder="domain.placeholder"
          size="small"
      >
        <a-select-option v-for="item in domain.optionsList" :value="item">
          {{ item }}
        </a-select-option>
      </a-select>


    </a-form-model-item>
    <!--    <a-button type="primary" html-type="submit" @click="submitForm('dynamicValidateForm')"-->
    <!--              style="margin-top: auto;height: 45px;">-->
    <!--      智能创造文案-->
    <!--    </a-button>-->
  </a-form-model>

</template>

<script>
export default ({
  props: {
    cardData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    userForms: {
      get() {
        return this.cardData.userForms
      }
    }
  },
  data() {
    return {
      form: {
        domains: []
      },
      formItemLayoutWithOutLabel: {},
      formItemLayout: {}
    }
  },
  methods: {
    submitForm() {
      const userForms = this.cardData.userForms
      let text = {}
      userForms.forEach((item, i) => {
        if (item.value) {
          text[item.label] = item.value
        }
      })
      this.$emit("submitForm", text)
    },
    init() {
      const userForms = this.cardData.userForms
      // console.log("--->", userForms)
      if (userForms) {
        userForms.forEach(item => {
          // this.$set(item, 'value', item.defaultValue)
          // console.log(11, item)
          // this.$set(this.rules, item.label, [
          //   {
          //     required: item.isRequired === 1,
          //     message: '请输入必填信息',
          //     trigger: item.formType === 'input' ? 'blur' : 'change'
          //   },
          // ])
        })
      }
      console.log(userForms)
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.init()
    })
  }
})
</script>

<style lang="scss">
.form-model {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 15px;
}

.ant-form-item-label {
  line-height: 25px;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-input, .ant-select-selection {
  border-radius: 0;
  font-weight: normal;
}
</style>